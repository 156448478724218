@layer modules, ui, base;
@layer ui {
  .sk-Button_main__8xbMi {
  --sk-Button-y-padding: var(--sk-space-12);
  --sk-Button-x-small-padding: var(--sk-space-12);
  --sk-Button-x-padding: var(--sk-space-16);
  --sk-Button-text-spacing: var(--sk-space-4);
  --sk-Button-icon-gap: var(--sk-space-4);

  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding-top: var(--sk-Button-y-padding);
  padding-bottom: var(--sk-Button-y-padding);
  transition: background-color 100ms ease-out;
  border: none;
  outline: none;
  font-family: var(--sk-typography-action-button-text-typography-m-font-family);
  text-decoration: none;
  cursor: pointer;
  gap: calc(var(--sk-Button-text-spacing) + var(--sk-Button-icon-gap));
}

.sk-Button_icon__MfDio {
  flex-shrink: 0;
}

.sk-Button_main--loading__Bvp4c {
  color: transparent;
  cursor: progress;
}

.sk-Button_main--loading__Bvp4c .sk-Button_loadingIcon__xSPkR {
  color: initial;
}

.sk-Button_loadingIcon__xSPkR {
  position: absolute;
  inset: 0;
  margin: auto;
  animation: sk-Button_spin__0T9Rc 1.5s linear infinite;
}

@keyframes sk-Button_spin__0T9Rc {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.sk-Button_main--disabled__3bTdp {
  cursor: not-allowed;
}

/* Sizes */
.sk-Button_main--small__I4ZXK {
  padding-right: calc(var(--sk-Button-x-small-padding) + var(--sk-Button-text-spacing));
  padding-left: calc(var(--sk-Button-x-small-padding) + var(--sk-Button-text-spacing));
  border-radius: var(--sk-action-button-radius-s);
  font-size: var(--sk-typography-action-button-text-typography-s-font-size);
  font-weight: var(--sk-typography-action-button-text-typography-s-font-weight);
  line-height: var(--sk-typography-action-button-text-typography-s-line-height);
}

.sk-Button_main--medium__vyUkg {
  border-radius: var(--sk-action-button-radius-m);
  font-size: var(--sk-typography-action-button-text-typography-m-font-size);
  font-weight: var(--sk-typography-action-button-text-typography-m-font-weight);
  line-height: var(--sk-typography-action-button-text-typography-m-line-height);
}

.sk-Button_main--large__g_Uug {
  border-radius: var(--sk-action-button-radius-l);
  font-size: var(--sk-typography-action-button-text-typography-l-font-size);
  font-weight: var(--sk-typography-action-button-text-typography-l-font-weight);
  line-height: var(--sk-typography-action-button-text-typography-l-line-height);
}

/* Spacing */
.sk-Button_main--medium__vyUkg,
.sk-Button_main--large__g_Uug {
  padding-right: calc(var(--sk-Button-x-padding) + var(--sk-Button-text-spacing));
  padding-left: calc(var(--sk-Button-x-padding) + var(--sk-Button-text-spacing));
}

.sk-Button_main--small__I4ZXK.sk-Button_main--withIcon__U76B5 {
  padding-right: calc(var(--sk-Button-x-small-padding) + var(--sk-Button-text-spacing));
  padding-left: var(--sk-Button-x-small-padding);
}

.sk-Button_main--medium__vyUkg.sk-Button_main--withIcon__U76B5,
.sk-Button_main--large__g_Uug.sk-Button_main--withIcon__U76B5 {
  padding-right: calc(var(--sk-Button-x-padding) + var(--sk-Button-text-spacing));
  padding-left: var(--sk-Button-x-padding);
}

/* Variants & Background */
/* On background light */
.sk-Button_main--light__Bkfce.sk-Button_main--primary__YNSKR:not(.sk-Button_main--loading__Bvp4c) {
  background-color: var(--sk-action-button-color-background-primary-default-light);
  color: var(--sk-action-button-color-primary-default-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--primary__YNSKR:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):hover {
  background-color: var(--sk-action-button-color-background-primary-hover-light);
  color: var(--sk-action-button-color-primary-hover-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--primary__YNSKR:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):focus-visible {
  background-color: var(--sk-action-button-color-background-primary-focus-light);
  box-shadow: var(--sk-ressource-focus-ring-shadow-light-shadow-3), var(--sk-ressource-focus-ring-shadow-light-shadow-2),
    var(--sk-ressource-focus-ring-shadow-light-shadow-1);
  color: var(--sk-action-button-color-primary-focus-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--primary__YNSKR:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):active {
  background-color: var(--sk-action-button-color-background-primary-active-light);
  color: var(--sk-action-button-color-primary-active-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--primary__YNSKR.sk-Button_main--loading__Bvp4c {
  background-color: var(--sk-action-button-color-background-primary-active-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--primary__YNSKR.sk-Button_main--disabled__3bTdp {
  background-color: var(--sk-action-color-background-primary-disabled-light);
  color: var(--sk-action-button-color-primary-disabled-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--secondary__7_9DM {
  outline: 2px solid var(--sk-action-button-color-border-secondary-default-light);
  outline-offset: -2px;
  background-color: transparent;
}

.sk-Button_main--light__Bkfce.sk-Button_main--secondary__7_9DM:not(.sk-Button_main--loading__Bvp4c) {
  color: var(--sk-action-button-color-secondary-default-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--secondary__7_9DM:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):hover {
  background-color: var(--sk-action-button-color-background-secondary-hover-light);
  color: var(--sk-action-button-color-secondary-hover-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--secondary__7_9DM:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):focus-visible {
  background-color: var(--sk-action-button-color-background-secondary-focus-light);
  box-shadow: var(--sk-ressource-focus-ring-shadow-light-shadow-3), var(--sk-ressource-focus-ring-shadow-light-shadow-2),
    var(--sk-ressource-focus-ring-shadow-light-shadow-1);
  color: var(--sk-action-button-color-secondary-focus-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--secondary__7_9DM:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):active {
  background-color: var(--sk-action-button-color-background-secondary-active-light);
  color: var(--sk-action-button-color-secondary-active-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--secondary__7_9DM.sk-Button_main--loading__Bvp4c {
  background-color: var(--sk-action-button-color-background-secondary-active-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--secondary__7_9DM.sk-Button_main--disabled__3bTdp {
  outline: 2px solid var(--sk-action-button-color-border-secondary-disabled-light);
  outline-offset: -2px;
  color: var(--sk-action-button-color-secondary-disabled-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--tertiary__pCNHc:not(.sk-Button_main--loading__Bvp4c) {
  background-color: transparent;
  color: var(--sk-action-button-color-tertiary-default-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--tertiary__pCNHc:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):hover {
  background-color: var(--sk-action-button-color-background-tertiary-hover-light);
  color: var(--sk-action-button-color-tertiary-hover-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--tertiary__pCNHc:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):focus-visible {
  background-color: var(--sk-action-button-color-background-tertiary-focus-light);
  box-shadow: var(--sk-ressource-focus-ring-shadow-light-shadow-3), var(--sk-ressource-focus-ring-shadow-light-shadow-2),
    var(--sk-ressource-focus-ring-shadow-light-shadow-1);
  color: var(--sk-action-button-color-tertiary-focus-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--tertiary__pCNHc:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):active {
  background-color: var(--sk-action-button-color-background-tertiary-active-light);
  color: var(--sk-action-button-color-tertiary-active-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--tertiary__pCNHc.sk-Button_main--loading__Bvp4c {
  background-color: var(--sk-action-button-color-background-tertiary-active-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--tertiary__pCNHc.sk-Button_main--disabled__3bTdp {
  color: var(--sk-action-button-color-tertiary-disabled-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--highlight__paOrt:not(.sk-Button_main--loading__Bvp4c) {
  background-color: var(--sk-action-button-color-background-highlight-default);
  color: var(--sk-action-button-color-highlight-default);
}

.sk-Button_main--light__Bkfce.sk-Button_main--highlight__paOrt:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):hover {
  background-color: var(--sk-action-button-color-background-highlight-hover);
  color: var(--sk-action-button-color-highlight-hover);
}

.sk-Button_main--light__Bkfce.sk-Button_main--highlight__paOrt:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):focus-visible {
  background-color: var(--sk-action-button-color-background-highlight-focus);
  box-shadow: var(--sk-ressource-focus-ring-shadow-light-shadow-3), var(--sk-ressource-focus-ring-shadow-light-shadow-2),
    var(--sk-ressource-focus-ring-shadow-light-shadow-1);
  color: var(--sk-action-button-color-highlight-focus);
}

.sk-Button_main--light__Bkfce.sk-Button_main--highlight__paOrt:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):active {
  background-color: var(--sk-action-button-color-background-highlight-active);
  color: var(--sk-action-button-color-highlight-active);
}

.sk-Button_main--light__Bkfce.sk-Button_main--highlight__paOrt.sk-Button_main--loading__Bvp4c {
  background-color: var(--sk-action-button-color-background-highlight-active);
}

.sk-Button_main--light__Bkfce.sk-Button_main--highlight__paOrt.sk-Button_main--disabled__3bTdp {
  background-color: var(--sk-action-button-color-background-highlight-disabled);
  color: var(--sk-action-button-color-highlight-disabled);
}

/* On background dark */
.sk-Button_main--dark__reu4z.sk-Button_main--primary__YNSKR {
  background-color: var(--sk-action-button-color-background-primary-default-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--primary__YNSKR:not(.sk-Button_main--loading__Bvp4c) {
  color: var(--sk-action-button-color-primary-default-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--primary__YNSKR:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):hover {
  background-color: var(--sk-action-button-color-background-primary-hover-dark);
  color: var(--sk-action-button-color-primary-hover-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--primary__YNSKR:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):focus-visible {
  background-color: var(--sk-action-button-color-background-primary-focus-dark);
  box-shadow: var(--sk-ressource-focus-ring-shadow-dark-shadow-3), var(--sk-ressource-focus-ring-shadow-dark-shadow-2),
    var(--sk-ressource-focus-ring-shadow-dark-shadow-1);
  color: var(--sk-action-button-color-primary-focus-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--primary__YNSKR:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):active {
  background-color: var(--sk-action-button-color-background-primary-active-dark);
  color: var(--sk-action-button-color-primary-active-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--primary__YNSKR.sk-Button_main--loading__Bvp4c {
  background-color: var(--sk-action-button-color-background-primary-active-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--primary__YNSKR.sk-Button_main--disabled__3bTdp {
  background-color: var(--sk-action-color-background-primary-disabled-dark);
  color: var(--sk-action-button-color-primary-disabled-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--secondary__7_9DM {
  outline: 2px solid var(--sk-action-button-color-border-secondary-default-dark);
  outline-offset: -2px;
  background-color: transparent;
}

.sk-Button_main--dark__reu4z.sk-Button_main--secondary__7_9DM:not(.sk-Button_main--loading__Bvp4c) {
  color: var(--sk-action-button-color-secondary-default-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--secondary__7_9DM:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):hover {
  background-color: var(--sk-action-button-color-background-secondary-hover-dark);
  color: var(--sk-action-button-color-secondary-hover-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--secondary__7_9DM:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):focus-visible {
  background-color: var(--sk-action-button-color-background-secondary-focus-dark);
  box-shadow: var(--sk-ressource-focus-ring-shadow-dark-shadow-3), var(--sk-ressource-focus-ring-shadow-dark-shadow-2),
    var(--sk-ressource-focus-ring-shadow-dark-shadow-1);
  color: var(--sk-action-button-color-secondary-focus-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--secondary__7_9DM:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):active {
  background-color: var(--sk-action-button-color-background-secondary-active-dark);
  color: var(--sk-action-button-color-secondary-active-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--secondary__7_9DM.sk-Button_main--loading__Bvp4c {
  background-color: var(--sk-action-button-color-background-secondary-active-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--secondary__7_9DM.sk-Button_main--disabled__3bTdp {
  outline: 2px solid var(--sk-action-button-color-border-secondary-disabled-dark);
  outline-offset: -2px;
  color: var(--sk-action-button-color-secondary-disabled-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--tertiary__pCNHc:not(.sk-Button_main--loading__Bvp4c) {
  background-color: transparent;
  color: var(--sk-action-button-color-tertiary-default-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--tertiary__pCNHc:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):hover {
  background-color: var(--sk-action-button-color-background-tertiary-hover-dark);
  color: var(--sk-action-button-color-tertiary-hover-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--tertiary__pCNHc:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):focus-visible {
  background-color: var(--sk-action-button-color-background-tertiary-focus-dark);
  box-shadow: var(--sk-ressource-focus-ring-shadow-dark-shadow-3), var(--sk-ressource-focus-ring-shadow-dark-shadow-2),
    var(--sk-ressource-focus-ring-shadow-dark-shadow-1);
  color: var(--sk-action-button-color-tertiary-focus-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--tertiary__pCNHc:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):active {
  background-color: var(--sk-action-button-color-background-tertiary-active-dark);
  color: var(--sk-action-button-color-tertiary-active-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--tertiary__pCNHc.sk-Button_main--loading__Bvp4c {
  background-color: var(--sk-action-button-color-background-tertiary-active-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--tertiary__pCNHc.sk-Button_main--disabled__3bTdp {
  color: var(--sk-action-button-color-tertiary-disabled-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--highlight__paOrt:not(.sk-Button_main--loading__Bvp4c) {
  background-color: var(--sk-action-button-color-background-highlight-default);
  color: var(--sk-action-button-color-highlight-default);
}

.sk-Button_main--dark__reu4z.sk-Button_main--highlight__paOrt:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):hover {
  background-color: var(--sk-action-button-color-background-highlight-hover);
  color: var(--sk-action-button-color-highlight-hover);
}

.sk-Button_main--dark__reu4z.sk-Button_main--highlight__paOrt:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):focus-visible {
  background-color: var(--sk-action-button-color-background-highlight-focus);
  box-shadow: var(--sk-ressource-focus-ring-shadow-dark-shadow-3), var(--sk-ressource-focus-ring-shadow-dark-shadow-2),
    var(--sk-ressource-focus-ring-shadow-dark-shadow-1);
  color: var(--sk-action-button-color-highlight-focus);
}

.sk-Button_main--dark__reu4z.sk-Button_main--highlight__paOrt:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):active {
  outline: none;
  background-color: var(--sk-action-button-color-background-highlight-active);
  box-shadow: none;
  color: var(--sk-action-button-color-highlight-active);
}

.sk-Button_main--dark__reu4z.sk-Button_main--highlight__paOrt.sk-Button_main--loading__Bvp4c {
  background-color: var(--sk-action-button-color-background-highlight-active);
}

.sk-Button_main--dark__reu4z.sk-Button_main--highlight__paOrt.sk-Button_main--disabled__3bTdp {
  background-color: var(--sk-action-button-color-background-highlight-disabled);
  color: var(--sk-action-button-color-highlight-disabled);
}

}
@layer ui {
  .sk-Checkbox_container__iYz32 {
  position: relative;
}

.sk-Checkbox_main__eUdvn {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  gap: var(--sk-space-8);
  padding: var(--sk-space-12);
  transition: background-color 100ms ease-out;
  border-radius: var(--sk-form-checkbox-radius);
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.sk-Checkbox_hiddenCheckboxInput__0NUuq {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  border: 0;
}

.sk-Checkbox_checkbox__y5PLh {
  display: inline-flex;
  box-sizing: border-box;
  flex-shrink: 0;
  align-self: center;
  width: var(--sk-size-20);
  height: var(--sk-size-20);
  margin: var(--sk-space-2) 0;
  border: var(--sk-size-2) solid;
  border-radius: var(--sk-form-checkbox-input-radius);
  place-items: center center;
}

.sk-Checkbox_label__S3CpC {
  font-family: var(--sk-typography-form-checkbox-typography-text-m-font-family);
  font-size: var(--sk-typography-form-checkbox-typography-text-m-font-size);
  font-weight: var(--sk-typography-form-checkbox-typography-text-m-font-weight);
  line-height: var(--sk-typography-form-checkbox-typography-text-m-line-height);
}

.sk-Checkbox_labelWrapper--dropdown-item__TMFtl {
  display: flex;
  align-items: center;
  min-width: 0;
  gap: var(--sk-space-4);
}

.sk-Checkbox_label--dropdown-item__5FX5b {
  display: -webkit-box;
  overflow: hidden;
  color: var(--sk-color-black);
  font-family: var(--sk-typography-body-2-regular-font-family);
  font-size: var(--sk-typography-body-2-regular-font-size);
  font-weight: var(--sk-typography-body-2-regular-font-weight);
  line-height: var(--sk-typography-body-2-regular-line-height);
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.sk-Checkbox_main--checked__6uyhF .sk-Checkbox_label--dropdown-item__5FX5b {
  font-family: var(--sk-typography-body-2-semibold-font-family);
  font-size: var(--sk-typography-body-2-semibold-font-size);
  font-weight: var(--sk-typography-body-2-semibold-font-weight);
  line-height: var(--sk-typography-body-2-semibold-line-height);
}

.sk-Checkbox_secondaryLabel__jzvUl {
  margin-left: var(--sk-space-8);
  color: var(--sk-color-grey-600);
  font-family: var(--sk-typography-form-checkbox-typography-text-m-font-family);
  font-size: var(--sk-typography-form-checkbox-typography-text-m-font-size);
  font-weight: var(--sk-typography-form-checkbox-typography-text-m-font-weight);
  line-height: var(--sk-typography-form-checkbox-typography-text-m-line-height);
}

.sk-Checkbox_secondaryLabel--dropdown-item__6oeMp {
  overflow: hidden;
  color: var(--sk-color-grey-600);
  font-family: var(--sk-typography-body-2-regular-font-family);
  font-size: var(--sk-typography-body-2-regular-font-size);
  font-weight: var(--sk-typography-body-2-regular-font-weight);
  line-height: var(--sk-typography-body-2-regular-line-height);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sk-Checkbox_errorMessage___j55S {
  padding: var(--sk-space-2) var(--sk-space-12);
  color: var(--sk-color-error-500);
}

.sk-Checkbox_checkedState__IoFPY {
  flex-shrink: 0;
}

.sk-Checkbox_main--disabled__Tug8s {
  cursor: not-allowed;
}

.sk-Checkbox_main--light__bacP3 {
  color: var(--sk-form-checkbox-color-default-light);
}

.sk-Checkbox_main--dark__rFl_O {
  color: var(--sk-form-checkbox-color-default-dark);
}

.sk-Checkbox_main--light__bacP3:hover:not(.sk-Checkbox_main--disabled__Tug8s, .sk-Checkbox_main--dropdown-item__nNJXx) {
  background-color: var(--sk-form-checkbox-color-background-hover-light);
  color: var(--sk-form-checkbox-color-hover-light);
}

.sk-Checkbox_main--dark__rFl_O:hover:not(.sk-Checkbox_main--disabled__Tug8s, .sk-Checkbox_main--dropdown-item__nNJXx) {
  background-color: var(--sk-form-checkbox-color-background-hover-dark);
  color: var(--sk-form-checkbox-color-hover-dark);
}

input:focus-visible + .sk-Checkbox_main__eUdvn {
  outline: none;
  box-shadow: var(--sk-shadow-focus-ring-light-shadow-3), var(--sk-shadow-focus-ring-light-shadow-2),
    var(--sk-shadow-focus-ring-light-shadow-1);
}

input:focus-visible + .sk-Checkbox_main--light__bacP3 {
  background-color: var(--sk-form-checkbox-color-background-focus-light);
  color: var(--sk-form-checkbox-color-focus-light);
}

input:focus-visible + .sk-Checkbox_main--dark__rFl_O {
  background-color: var(--sk-form-checkbox-color-background-focus-dark);
  color: var(--sk-form-checkbox-color-focus-dark);
}

.sk-Checkbox_main--light__bacP3.sk-Checkbox_main--disabled__Tug8s {
  color: var(--sk-form-checkbox-color-disabled-light);
}

.sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--disabled__Tug8s {
  color: var(--sk-form-checkbox-color-disabled-dark);
}

.sk-Checkbox_main--light__bacP3.sk-Checkbox_main--checked__6uyhF.sk-Checkbox_main--disabled__Tug8s,
.sk-Checkbox_main--light__bacP3.sk-Checkbox_main--indeterminate___tYpt.sk-Checkbox_main--disabled__Tug8s {
  color: var(--sk-form-checkbox-color-disabled-light);
}

.sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--checked__6uyhF.sk-Checkbox_main--disabled__Tug8s,
.sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--indeterminate___tYpt.sk-Checkbox_main--disabled__Tug8s {
  color: var(--sk-form-checkbox-color-disabled-dark);
}

.sk-Checkbox_main--light__bacP3:hover:not(.sk-Checkbox_main--disabled__Tug8s) .sk-Checkbox_checkbox__y5PLh {
  border-color: var(--sk-form-checkbox-input-color-border-hover-light);
  color: var(--sk-form-checkbox-input-color-hover-light);
}

.sk-Checkbox_main--light__bacP3:hover:not(.sk-Checkbox_main--disabled__Tug8s, .sk-Checkbox_main--dropdown-item__nNJXx) + .sk-Checkbox_main--light__bacP3.sk-Checkbox_main--checked__6uyhF .sk-Checkbox_checkbox__y5PLh,
.sk-Checkbox_main--light__bacP3:hover:not(.sk-Checkbox_main--disabled__Tug8s, .sk-Checkbox_main--dropdown-item__nNJXx) + .sk-Checkbox_main--light__bacP3.sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--indeterminate___tYpt .sk-Checkbox_checkbox__y5PLh {
  background-color: var(--sk-form-checkbox-input-color-background-checked-hover-light);
}

.sk-Checkbox_main--dark__rFl_O:hover:not(.sk-Checkbox_main--disabled__Tug8s) .sk-Checkbox_checkbox__y5PLh {
  border-color: var(--sk-form-checkbox-input-color-border-hover-dark);
  color: var(--sk-form-checkbox-input-color-hover-dark);
}

.sk-Checkbox_main--dark__rFl_O:hover:not(.sk-Checkbox_main--disabled__Tug8s, .sk-Checkbox_main--dropdown-item__nNJXx) + .sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--checked__6uyhF .sk-Checkbox_checkbox__y5PLh,
.sk-Checkbox_main--dark__rFl_O:hover:not(.sk-Checkbox_main--disabled__Tug8s, .sk-Checkbox_main--dropdown-item__nNJXx) + .sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--indeterminate___tYpt .sk-Checkbox_checkbox__y5PLh {
  background-color: var(--sk-form-checkbox-input-color-background-checked-hover-dark);
}

input:focus-visible + .sk-Checkbox_main--light__bacP3 .sk-Checkbox_checkbox__y5PLh {
  border-color: var(--sk-form-checkbox-input-color-border-focus-light);
  color: var(--sk-form-checkbox-input-color-focus-light);
}

input:focus-visible + .sk-Checkbox_main--light__bacP3.sk-Checkbox_main--checked__6uyhF .sk-Checkbox_checkbox__y5PLh,
input:focus-visible + .sk-Checkbox_main--light__bacP3.sk-Checkbox_main--light__bacP3.sk-Checkbox_main--indeterminate___tYpt .sk-Checkbox_checkbox__y5PLh {
  background-color: var(--sk-form-checkbox-input-color-background-checked-focus-light);
}

input:focus-visible + .sk-Checkbox_main--dark__rFl_O .sk-Checkbox_checkbox__y5PLh {
  border-color: var(--sk-form-checkbox-input-color-border-focus-dark);
  color: var(--sk-form-checkbox-input-color-focus-dark);
}

input:focus-visible + .sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--checked__6uyhF .sk-Checkbox_checkbox__y5PLh,
input:focus-visible + .sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--indeterminate___tYpt .sk-Checkbox_checkbox__y5PLh {
  background-color: var(--sk-form-checkbox-input-color-background-checked-focus-dark);
}

.sk-Checkbox_main--light__bacP3.sk-Checkbox_main--disabled__Tug8s .sk-Checkbox_checkbox__y5PLh {
  border-color: var(--sk-form-checkbox-input-color-border-disabled-light);
  color: var(--sk-form-checkbox-input-color-disabled-light);
}

.sk-Checkbox_main--light__bacP3.sk-Checkbox_main--checked__6uyhF.sk-Checkbox_main--disabled__Tug8s .sk-Checkbox_checkbox__y5PLh,
.sk-Checkbox_main--light__bacP3.sk-Checkbox_main--indeterminate___tYpt.sk-Checkbox_main--disabled__Tug8s .sk-Checkbox_checkbox__y5PLh {
  background-color: var(--sk-form-checkbox-input-color-background-checked-disabled-light);
}

.sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--disabled__Tug8s .sk-Checkbox_checkbox__y5PLh {
  border-color: var(--sk-form-checkbox-input-color-border-disabled-dark);
  color: var(--sk-form-checkbox-input-color-disabled-dark);
}

.sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--checked__6uyhF.sk-Checkbox_main--disabled__Tug8s .sk-Checkbox_checkbox__y5PLh,
.sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--indeterminate___tYpt.sk-Checkbox_main--disabled__Tug8s .sk-Checkbox_checkbox__y5PLh {
  background-color: var(--sk-form-checkbox-input-color-background-checked-disabled-dark);
}

.sk-Checkbox_main--light__bacP3 .sk-Checkbox_secondaryLabel__jzvUl {
  color: var(--sk-form-checkbox-secondary-label-color-default-light);
}

.sk-Checkbox_main--dark__rFl_O .sk-Checkbox_secondaryLabel__jzvUl {
  color: var(--sk-form-checkbox-secondary-label-color-default-dark);
}

.sk-Checkbox_main--light__bacP3:hover:not(.sk-Checkbox_main--disabled__Tug8s) .sk-Checkbox_secondaryLabel__jzvUl {
  color: var(--sk-form-checkbox-secondary-label-color-hover-light);
}

.sk-Checkbox_main--dark__rFl_O:hover:not(.sk-Checkbox_main--disabled__Tug8s) .sk-Checkbox_secondaryLabel__jzvUl {
  color: var(--sk-form-checkbox-secondary-label-color-hover-dark);
}

input:focus-visible + .sk-Checkbox_main--light__bacP3 .sk-Checkbox_secondaryLabel__jzvUl {
  color: var(--sk-form-checkbox-secondary-label-color-focus-light);
}

input:focus-visible + .sk-Checkbox_main--dark__rFl_O .sk-Checkbox_secondaryLabel__jzvUl {
  color: var(--sk-form-checkbox-secondary-label-color-focus-dark);
}

.sk-Checkbox_main--light__bacP3.sk-Checkbox_main--disabled__Tug8s .sk-Checkbox_secondaryLabel__jzvUl {
  color: var(--sk-form-checkbox-secondary-label-color-disabled-light);
}

.sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--disabled__Tug8s .sk-Checkbox_secondaryLabel__jzvUl {
  color: var(--sk-form-checkbox-secondary-label-color-disabled-dark);
}

.sk-Checkbox_main--light__bacP3 .sk-Checkbox_checkedState__IoFPY {
  color: var(--sk-form-checkbox-input-color-default-light);
}

.sk-Checkbox_main--dark__rFl_O .sk-Checkbox_checkedState__IoFPY {
  color: var(--sk-form-checkbox-input-color-default-dark);
}

.sk-Checkbox_main--light__bacP3.sk-Checkbox_main--checked__6uyhF .sk-Checkbox_checkbox__y5PLh,
.sk-Checkbox_main--light__bacP3.sk-Checkbox_main--indeterminate___tYpt .sk-Checkbox_checkbox__y5PLh {
  background-color: var(--sk-form-checkbox-input-color-background-checked-default-light);
}

.sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--checked__6uyhF .sk-Checkbox_checkbox__y5PLh,
.sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--indeterminate___tYpt .sk-Checkbox_checkbox__y5PLh {
  background-color: var(--sk-form-checkbox-input-color-background-checked-default-dark);
}

}
